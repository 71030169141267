
  #invoice {
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #invoice td, #invoice th {
    border: 1px solid #dde;
    padding: 8px;
    font-family:Roboto,Helvetica,Arial,sans-serif
  }
  #invoice td {
    color: #3c4858;
  }

  .price-title {
    color: white !important;
    font-size: 1rem;
    font-weight: 300;
  }
  .price-card {
    color: black !important;
    font-size: 1.3rem;
    font-weight: 300;
  }
  .price {
    font-size: 1.1rem;
    font-weight: 300;
  }
  
  /* #invoice tr:nth-child(even){background-color: #f2f2f2;} */
  
  #invoice tr:hover {background-color: #ddd;}
  
  #invoice th {
    padding: 12px;
    text-align: left;
    background-color: #4091e2;
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }
